import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import './index.scss';
import { PropsWithChildren } from 'react';

const INSTANT_OPEN_DURATION = 0;

interface TooltipProps {
  content: string;
  showArrow?: boolean;
  delayDuration?: number;
  side?: 'top' | 'right' | 'bottom' | 'left' | undefined;
  align?: 'start' | 'center' | 'end' | undefined;
  portal?: boolean;
  sideOffset?: number;
}

const TooltipContent = ({ content, showArrow, side, align, sideOffset }: TooltipProps) => {
  return (
    <TooltipPrimitive.Content side={side} align={align} className="TooltipContent" sideOffset={sideOffset}>
      {content}
      {showArrow && <TooltipPrimitive.Arrow width={11} height={5} className="TooltipArrow" />}
    </TooltipPrimitive.Content>
  );
};

const Tooltip = ({
  children,
  content,
  showArrow = false,
  side = 'bottom',
  align = 'center',
  delayDuration = INSTANT_OPEN_DURATION,
  portal = true,
  sideOffset = 5
}: PropsWithChildren<TooltipProps>) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={delayDuration}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        {portal ? (
          <TooltipPrimitive.Portal>
            <TooltipContent content={content} showArrow={showArrow} side={side} align={align} sideOffset={sideOffset} />
          </TooltipPrimitive.Portal>
        ) : (
          <TooltipContent content={content} showArrow={showArrow} side={side} align={align} sideOffset={sideOffset} />
        )}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default Tooltip;
